<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">代理商管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">代理商业绩</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="seaUserName"
                type="text"
                size="small"
                placeholder="请输入学员姓名"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="seaUserIdcard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="seaUserMobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div>
            <div title="培训地区" class="searchboxItem ci-full">
              <span class="itemLabel">培训地区:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
          </div>
          <div class="searchbox" style="padding-top: 10px">
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </div>
            <div
              title="代理商"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">代理商:</span>
              <el-select
                v-model="agencyId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superAgencySelect"
                @visible-change="clearAgencySearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >联系人姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >联系人电话</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 30px;
                        width: 160px;
                      "
                      >企业名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="UserName"
                      v-on:input="superAgencySelect"
                      type="text"
                      size="small"
                      placeholder="联系人姓名"
                      clearable
                      style="width: 120px"
                    />
                    <el-input
                      v-model="Userphone"
                      v-on:input="superAgencySelect"
                      type="text"
                      size="small"
                      placeholder="联系人电话"
                      clearable
                      style="margin-left: 30px; width: 140px"
                    />
                    <el-input
                      v-model="compName"
                      v-on:input="superAgencySelect"
                      type="text"
                      size="small"
                      placeholder="企业名称"
                      clearable
                      style="margin-left: 30px; width: 160px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in agencyList"
                  :key="item.agencyId"
                  :label="item.name"
                  :value="item.agencyId"
                >
                  <span
                    style="
                      width: 100px;
                      font-size: 13px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ item.name }}</span
                  >
                  <span
                    style="
                      width: 140px;
                      margin-left: 50px;
                      font-size: 13px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ item.photo }}</span
                  >
                  <span
                    style="
                      width: 160px;
                      margin-left: 50px;
                      font-size: 13px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ item.compName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="认证状态" class="searchboxItem ci-full">
              <span class="itemLabel">认证状态:</span>
              <el-select
                clearable
                size="small"
                v-model="authentication"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in authenticationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="是否有效" class="searchboxItem ci-full">
              <span class="itemLabel">是否有效:</span>
              <el-select
                clearable
                size="small"
                v-model="isValid"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in validList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="padding-top: 10px">
            <div title="导入时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 90px">导入时间:</span>
              <el-date-picker
                v-model="exportDate"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="入班时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 90px">入班时间:</span>
              <el-date-picker
                v-model="theClassTime"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-select
                clearable
                size="small"
                v-model="studyState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in studyStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="padding-top: 10px">
            <div title="学员类型" class="searchboxItem ci-full">
              <span class="itemLabel">学员类型:</span>
              <el-select
                clearable
                size="small"
                v-model="studentType"
                placeholder="请选择学员类型"
              >
                <el-option
                  v-for="item in studentTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div>
          <span style="padding: 8px 10px">导入人次:{{ importNum || 0 }}人</span>
          <span style="padding: 8px 10px"
            >入班人次:{{ inTheClassNum || 0 }}人</span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @sort-change="changeSort"
              :default-sort="{ prop: 'theClassTime', order: 'ascending' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="培训地区"
                align="left"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="职业/工种"
                align="center"
                show-overflow-tooltip
                prop="occupationName"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.occupationName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="left"
                prop="trainLevelName"
                show-overflow-tooltip
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.trainLevelName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="代理商姓名"
                align="left"
                prop="agencyName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="代理企业"
                align="left"
                prop="companyName"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="导入时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="认证状态"
                align="left"
                prop="authentication"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("AUTHENTICATION", scope.row.authentication)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="入班时间"
                align="left"
                prop="theClassTime"
                :sort-orders="['ascending', 'descending']"
                sortable="custom"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="学习状态"
                align="left"
                prop="studyState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("STUDY_STATUS", scope.row.studyState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="必修进度"
                align="left"
                prop="lessonNumPercentage"
                show-overflow-tooltip
                :sort-by="'lessonNumPercentage'"
                sortable="custom"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.lessonNumPercentage == "0%"
                      ? ""
                      : scope.row.lessonNumPercentage
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="选修进度"
                align="left"
                prop="electiveLessonNumPercentage"
                :sort-by="'electiveLessonNumPercentage'"
                sortable="custom"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.electiveLessonNumPercentage == "0%"
                      ? ""
                      : scope.row.electiveLessonNumPercentage
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否有效"
                align="left"
                prop="validState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.validState ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="left"
                prop="remark"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleCreate(scope.row)"
                    >有效性设置</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
      <el-dialog
        title="有效性设置"
        :visible.sync="dialogVisible"
        width="55%"
        top="3%"
        :center="true"
        :before-close="doClose"
        :close-on-click-modal="false"
      >
        <div class="ovy-a">
          <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
            <el-form-item label="是否有效" prop="validState">
              <el-radio-group
                v-model="Form.validState"
                @change="validStateChange"
              >
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注" prop="remark" v-if="!Form.validState">
              <el-input
                type="textarea"
                placeholder="请输入备注"
                v-model="Form.remark"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark1" v-else>
              <el-input
                type="textarea"
                placeholder="请输入备注"
                v-model="Form.remark"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
          style="display: flex; justify-content: center"
        >
          <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
          <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    var creditremark = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入备注"));
      } else if (value == "") {
        callback(new Error("请输入备注"));
      } else {
        callback();
      }
    };
    return {
      userId: "",
      agencyId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      UserName: "",
      Userphone: "",
      compName: "",
      exportDate: "",
      theClassTime: "",
      userList: [{}],
      agencyList: [{}],
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      areaId: "", //行政区划
      ruleForm: {
        Trainingtype: "", // 培训类型
      },
      importNum: "0",
      inTheClassNum: "0",
      authenticationList: [],
      isValid: "",
      validList: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      studyState: "",
      studyStatusList: [],
      authentication: "",
      params: {},
      Form: {
        validState: true,
        remark: "",
        remark1: "",
      },
      rules: {
        validState: [
          { required: true, message: "请选择是否有效", trigger: "change" },
        ],
        remark: [{ required: true, validator: creditremark, trigger: "blur" }],
        remark1: [{ required: false, trigger: "blur" }],
      },
      dialogVisible: false,
      studentTypeList: [], //学员类型
      studentType: "",
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getareatree();
    // this.superUserSelect();
    this.superAgencySelect();
    this.getauthenticationList();
    this.getstydyStatusList();
  },
  methods: {
    getstydyStatusList() {
      const studyStatusList = this.$setDictionary("STUDY_STATUS", "list") || [];
      let list = [];
      for (const key in studyStatusList) {
        list.push({
          value: key,
          label: studyStatusList[key],
        });
      }
      this.studyStatusList = list;
    },
    handleCreate(row) {
      this.agencyStudentId = row.agencyStudentId;
      this.dialogVisible = true;
      this.Form = {
        validState: row.validState,
        remark: row.remark,
      };
    },
    getbeforeValid(agencyStudentId) {
      this.$post("/agency/ma-agency-static/setVaildBefore", { agencyStudentId })
        .then((ret) => {
          let parmar = {
            agencyStudentId: this.agencyStudentId,
            validState: this.Form.validState,
            remark: this.Form.remark,
          };
          if (ret.data.hasOther == true) {
            this.$confirm(ret.data.message, "提示", {
              confirmButtonText: "确定修改",
              cancelButtonText: "取消修改",
              confirmButtonClass: "bgc-bv",
            }).then(() => {
              this.$post("/agency/ma-agency-static/setVaild", parmar)
                .then((ret) => {
                  if (ret.status == "0") {
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                    this.getData(-1);
                    this.doClose();
                  }
                })
                .catch((err) => {});
            });
          } else {
            this.$post("/agency/ma-agency-static/setVaild", parmar)
              .then((ret) => {
                if (ret.status == "0") {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  this.getData(-1);
                  this.doClose();
                }
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {
          this.dialogVisible = false;
          this.doClose();
        });
    },
    doClose(Form) {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.Form = {};
    },
    validStateChange(val) {
      if (val) {
        this.Form.remark = "";
      }
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.Form);
          if (this.Form.validState) {
            this.getbeforeValid(this.agencyStudentId);
          } else {
            let parmar = {
              agencyStudentId: this.agencyStudentId,
              validState: this.Form.validState,
              remark: this.Form.remark,
            };

            this.$post("/agency/ma-agency-static/setVaild", parmar)
              .then((ret) => {
                if (ret.status == "0") {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  this.getData(-1);
                  this.doClose();
                }
              })
              .catch((err) => {
                return;
              });
          }
        }
      });
    },
    getauthenticationList() {
      const authenticationlist = this.$setDictionary("AUTHENTICATION", "list");
      const studentType = this.$setDictionary("USERTYPE_SX", "list");
      const list = [];
      for (const key in authenticationlist) {
        list.push({
          value: key,
          label: authenticationlist[key],
        });
      }
      for (const key in studentType) {
        this.studentTypeList.push({
          value: key,
          label: studentType[key],
        });
      }
      this.authenticationList = list;
    },
    superAgencySelect(e) {
      this.$post(
        "/agency/ma-agency-static/search",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          name: this.UserName,
          photo: this.Userphone,
          compName: this.compName,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.agencyList = [{}];
        } else {
          this.agencyList = res.data.list;
        }
      });
    },
    clearAgencySearchModel(e) {
      if (e) {
        this.UserName = "";
        this.Userphone = "";
        this.compName = "";
        this.superAgencySelect();
      }
    },
    changeSort(row, clounm) {
      if (row.prop == "theClassTime") {
        this.theClassTimeStort = row.order;
        this.lessonNumPercentageStort = "";
        this.electiveLessonNumPercentageStort = "";
      }
      if (row.prop == "lessonNumPercentage") {
        this.lessonNumPercentageStort = row.order;
        this.theClassTimeStort = "";
        this.electiveLessonNumPercentageStort = "";
      }
      if (row.prop == "electiveLessonNumPercentage") {
        this.electiveLessonNumPercentageStort = row.order;
        this.lessonNumPercentageStort = "";
        this.theClassTimeStort = "";
      }
      this.getData();
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        validState: this.isValid,
      };
      if (this.seaUserName) {
        params.userName = this.seaUserName;
      }
      if (this.seaUserIdcard) {
        params.idcard = this.seaUserIdcard;
      }
      if (this.seaUserMobile) {
        params.mobile = this.seaUserMobile;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.studyState) {
        params.studyState = this.studyState;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.agencyId) {
        params.agencyId = this.agencyId;
      }
      if (this.exportDate) {
        params.startTime = this.exportDate[0];
        params.endTime = this.exportDate[1];
      }
      if (this.theClassTime) {
        params.theClassTimeStart = this.theClassTime[0];
        params.theClassTimeEnd = this.theClassTime[1];
      }
      if (this.authentication) {
        params.authentication = this.authentication;
      }
      if (this.studentType) {
        params.studentType = this.studentType;
      }
      if (this.theClassTimeStort) {
        params.theClassTimeStort = this.theClassTimeStort;
      }
      if (this.lessonNumPercentageStort) {
        params.lessonNumPercentageStort = this.lessonNumPercentageStort;
      }
      if (this.electiveLessonNumPercentageStort) {
        params.electiveLessonNumPercentageStort =
          this.electiveLessonNumPercentageStort;
      }
      this.doFetch({
        url: "/agency/ma-agency-static/pageList",
        params,
        pageNum,
      });
      this.getCount();
    },
    getCount() {
      const params = {
        validState: this.isValid,
      };
      if (this.seaUserName) {
        params.userName = this.seaUserName;
      }
      if (this.seaUserIdcard) {
        params.idcard = this.seaUserIdcard;
      }
      if (this.seaUserMobile) {
        params.mobile = this.seaUserMobile;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.studyState) {
        params.studyState = this.studyState;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.agencyId) {
        params.agencyId = this.agencyId;
      }
      if (this.exportDate) {
        params.startTime = this.exportDate[0];
        params.endTime = this.exportDate[1];
      }
      if (this.theClassTime) {
        params.theClassTimeStart = this.theClassTime[0];
        params.theClassTimeEnd = this.theClassTime[1];
      }
      if (this.authentication) {
        params.authentication = this.authentication;
      }
      if (this.studentType) {
        params.studentType = this.studentType;
      }
      this.$post("/agency/ma-agency-static/count", params)
        .then((ret) => {
          console.log(ret);
          if (ret.status == "0") {
            this.importNum = ret.data.importNum;
            this.inTheClassNum = ret.data.inTheClassNum;
          }
        })
        .catch((err) => {
          return;
        });
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },

    handleExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            validState: this.isValid,
          };
          if (this.seaUserName) {
            params.userName = this.seaUserName;
          }
          if (this.seaUserIdcard) {
            params.idcard = this.seaUserIdcard;
          }
          if (this.areaId) {
            params.areaId = this.areaId;
          }
          if (this.params.trainFormId) {
            params.trainTypeId = this.params.trainFormId;
          }
          if (this.params.levelFormId) {
            params.trainLevelId = this.params.levelFormId;
          }
          if (this.studyState) {
            params.studyState = this.studyState;
          }
          if (this.params.occFormId) {
            params.occupationId = this.params.occFormId;
          }
          if (this.params.postFormId) {
            params.postId = this.params.postFormId;
          }
          if (this.params.industryFormId) {
            params.industryId = this.params.industryFormId;
          }
          if (this.agencyId) {
            params.agencyId = this.agencyId;
          }
          if (this.exportDate) {
            params.startTime = this.exportDate[0];
            params.endTime = this.exportDate[1];
          }
          if (this.theClassTime) {
            params.theClassTimeStart = this.theClassTime[0];
            params.theClassTimeEnd = this.theClassTime[1];
          }
          if (this.authentication) {
            params.authentication = this.authentication;
          }
          if (this.seaUserMobile) {
            params.mobile = this.seaUserMobile;
          }
          if (this.studentType) {
            params.studentType = this.studentType;
          }
          this.$post(
            "/agency/ma-agency-static/maAgencyAchievementasyncExport",
            params
          ).then((res) => {
            if (res.status == "0") {
              let list = [];
              list.push(res.data);
              for (let item of list) {
                console.log(item);
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(re.message);
            }
          });
        })
        .catch(() => {});
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 10) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
